







































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  DxCircularGauge,
  DxScale,
  DxLabel,
  DxRangeContainer,
  DxRange,
  DxTitle,
  DxFont,
  DxTooltip,
  DxExport,
} from "devextreme-vue/circular-gauge";
import { dashboardCreationDto } from "@/shared/dtos/dashboardCreationDto";
import { UtilsNumber } from "@/utils/utils-number";
@Component({
  components: {
    DxCircularGauge,
    DxScale,
    DxLabel,
    DxRangeContainer,
    DxRange,
    DxTitle,
    DxFont,
    DxTooltip,
    DxExport,
  },
})
export default class BasicGaugeConfigurable extends Vue {
  @Prop() chart_prop!: dashboardCreationDto;
  public mounted() {
    console.log(this.chart_prop, "BasicGaugeConfigurable");
  }
  public customizeTooltip({ valueText }: any) {
    return {
      text: `${valueText}%`,
    };
  }
  public get color_of_text() {
    return this.valor_i_color(this.chart_prop.puntuacion100);
  }
  public get paleta_colores() {
    return this.chart_prop.configuracion.map((x) => x.color);
  }

  public valor_i_color(valor: number): string {
    for (let i = 0; i < this.chart_prop.configuracion.length; i++) {
      if (
        UtilsNumber.between(
          valor,
          this.chart_prop.configuracion[i].limit_min,
          this.chart_prop.configuracion[i].limit_max
        )
      ) {
        return this.chart_prop.configuracion[i].color;
      }
    }
    /*if (UtilsNumber.between(valor, 0, 33)) {
      return "#D13E38";
    } else if (UtilsNumber.between(valor, 33, 66)) {
      return "#EBB82B";
    } else if (UtilsNumber.between(valor, 66, 100)) {
      return "#33C481";
    }*/
    return "#FFFFFF";
  }

  public stilo(numero: number) {
    return (
      "height: 15px;width: 15px; background-color: " +
      this.valor_i_color(numero) +
      "; border-radius: 50%;"
    );
  }
}
